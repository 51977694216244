import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

import IconButton from "@mui/material/IconButton"
import { HiLink } from "react-icons/hi"

import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"
import Diversity3Icon from "@mui/icons-material/Diversity3"
import LocalCafeIcon from "@mui/icons-material/LocalCafe"

import FriendLinkForm from "./commit-friend-link"

import axios from "axios"

const NotFoundPage = ({ data, location }) => {
  const [profiles, setProfiles] = React.useState([])
  // const siteTitle = data.site.siteMetadata.title
  function ImageIcon(props) {
    const { src } = props
    return (
      <div
        style={{
          display: "flex",
          verticalAlign: "middle",
          alignItems: "center",
        }}
      >
        <img
          src={src}
          alt="laucher"
          style={{ width: "1.5rem", height: "1.5rem" }}
        />
      </div>
    )
  }

  const postData = async () => {
    const url = "https://api.czhlove.cn/v1/blog/getFriendLinks"
    try {
      const response = await axios.get(url)
      setProfiles(response.data.data)
    } catch (error) {
      console.error("请求失败", error)
    }
  }

  React.useEffect(() => {
    postData()
  }, [])

  return (
    <Layout
      location={location}
      title={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            sx={{
              mr: 1,
              backgroundColor: "action.selected",
              color: "text.primary",
            }}
          >
            <HiLink fontSize={20} />
          </IconButton>
          {"Blogroll"}
        </Box>
      }
    >
      <Seo title={"Links"} />
      <Container
        maxWidth="string"
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          maxWidth: "692px",
          "@media (max-width: 600px)": {
            px: "1.5rem",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="nav tabs example"
            value={2}
            sx={{
              "& .MuiTabs-flexContainer": {
                gap: "1rem",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "text.primary",
                height: "1px",
              },
            }}
          >
            <Tab
              label="博客"
              component="a"
              href="/"
              icon={<LocalCafeIcon />}
              iconPosition="start"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
            <Tab
              label="相册"
              component="a"
              href="/coolAlbum"
              icon={<PhotoLibraryIcon />}
              iconPosition="start"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",

                "&.Mui-selected": { color: "text.primary" },
              }}
            />
            <Tab
              label="博友们"
              component="a"
              href="/friend-links"
              icon={<Diversity3Icon />}
              iconPosition="start"
              sx={{
                textTransform: "capitalize",
                fontWeight: 400,
                minWidth: "60px",
                "&.Mui-selected": { color: "text.primary" },
              }}
            />
          </Tabs>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {Object.keys(profiles).map(key => {
            const profile = profiles[key]
            return (
              <a
                href={profile.url}
                key={key}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "40px",
                    backgroundColor: "action.selected",
                    color: "text.primary",
                    display: "flex",
                    padding: "4px 0",
                    justifyContent: "flex-start",
                    borderColor: "text.disabled",
                    borderRadius: 2,
                    transition: "transform 00.3s", // 添加过渡效果
                    "&:hover": {
                      // 鼠标悬停时的样式
                      textShadow: "0 2px 10px rgba(0, 0, 0, 0.2)", // 鼠标悬停时增强阴影
                      transform: "scale(1.05)", // 轻微放大效果
                      cursor: "pointer", // 显示鼠标指针为手型
                    },
                    "&:active": {
                      transform: "scale(0.98)", // 按下时轻微缩小
                      boxShadow: "none", // 移除阴影
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      margin: "0 1rem",
                    }}
                  >
                    <ImageIcon src={profile.iconSrc} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box sx={{ marginRight: "1rem" }}>{profile.name}</Box>
                    {profile.desc && (
                      <Typography
                        variant="caption"
                        sx={{ textTransform: "none", color: "text.disabled" }}
                      >
                        {profile.desc}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </a>
            )
          })}
        </Box>
        <FriendLinkForm />
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
